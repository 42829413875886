// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_Footer__r6DrJ{
    width:100%;
    background:#212121;
    padding:70px 50px;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.Footer_copyright__yYo-w{
    color:#fff;
    margin:0;
    border-top:1px solid #333;
    padding-top:20px;
}
.Footer_snssection__ZVhNI{
    height:50px;
    margin-bottom:30px;
}
.Footer_snssection__ZVhNI img{
    width:50px;
    height:50px;
    margin:0 10px;
    filter:grayscale(1);
    transition-duration:0.5s;
}
.Footer_snssection__ZVhNI img:hover{
    filter:grayscale(0);
}
.Footer_rozefooterlogo__NRlMj{
    margin-bottom:30px;
}

/* 모바일 버전 */
.Footer_mobileFooter__NH9b\\+{
    padding:50px 30px 30px 30px;
}
.Footer_mobileFooter__NH9b\\+ .Footer_rozefooterlogo__NRlMj{
    width:20%;
}
.Footer_mobileFooter__NH9b\\+ .Footer_snssection__ZVhNI img{
    width:40px;
    height:40px;
    filter:grayscale(0);
}
.Footer_mobileFooter__NH9b\\+ .Footer_copyright__yYo-w{
    font-size:13px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,QAAQ;IACR,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,wBAAwB;AAC5B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;;AAEA,WAAW;AACX;IACI,2BAA2B;AAC/B;AACA;IACI,SAAS;AACb;AACA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB","sourcesContent":[".Footer{\r\n    width:100%;\r\n    background:#212121;\r\n    padding:70px 50px;\r\n    box-sizing: border-box;\r\n    display:flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n.copyright{\r\n    color:#fff;\r\n    margin:0;\r\n    border-top:1px solid #333;\r\n    padding-top:20px;\r\n}\r\n.snssection{\r\n    height:50px;\r\n    margin-bottom:30px;\r\n}\r\n.snssection img{\r\n    width:50px;\r\n    height:50px;\r\n    margin:0 10px;\r\n    filter:grayscale(1);\r\n    transition-duration:0.5s;\r\n}\r\n.snssection img:hover{\r\n    filter:grayscale(0);\r\n}\r\n.rozefooterlogo{\r\n    margin-bottom:30px;\r\n}\r\n\r\n/* 모바일 버전 */\r\n.mobileFooter{\r\n    padding:50px 30px 30px 30px;\r\n}\r\n.mobileFooter .rozefooterlogo{\r\n    width:20%;\r\n}\r\n.mobileFooter .snssection img{\r\n    width:40px;\r\n    height:40px;\r\n    filter:grayscale(0);\r\n}\r\n.mobileFooter .copyright{\r\n    font-size:13px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": `Footer_Footer__r6DrJ`,
	"copyright": `Footer_copyright__yYo-w`,
	"snssection": `Footer_snssection__ZVhNI`,
	"rozefooterlogo": `Footer_rozefooterlogo__NRlMj`,
	"mobileFooter": `Footer_mobileFooter__NH9b+`
};
export default ___CSS_LOADER_EXPORT___;
