// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoInfo{
    width:100%;
    box-sizing: border-box;
    padding:30px 30px 50px 30px;
    background:#fff;
    font-family: "GongGothicLight";
    font-size:13px;
    color:#333;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.videoInfo ul{
    width:100%;
    margin-top:30px;
    box-sizing: border-box;
    padding:10px;
    border:1px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.videoInfo ul li{
    width:100%;
    border:1px solid #f5f5f5;
    box-sizing: border-box;
    padding-left:10px;
    border-top:none;
    background:#f9f9f9;
    height:40px;
    line-height: 40px;
    display:flex;
    justify-content: space-between;
}
.videoInfo ul li:first-child{
    border-top:1px solid #f5f5f5;
}
.videoInfo ul li{
    text-align: left;
}
.videoInfo ul li span{
    width:70%;
    height:100%;
    display: block;
    float: right;
    padding-left:10px;
    background:#fff;
}`, "",{"version":3,"sources":["webpack://./src/Board/Motiongraphic.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,sBAAsB;IACtB,2BAA2B;IAC3B,eAAe;IACf,8BAA8B;IAC9B,cAAc;IACd,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,eAAe;IACf,sBAAsB;IACtB,YAAY;IACZ,wBAAwB;IACxB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,wBAAwB;IACxB,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,8BAA8B;AAClC;AACA;IACI,4BAA4B;AAChC;AACA;IACI,gBAAgB;AACpB;AACA;IACI,SAAS;IACT,WAAW;IACX,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".videoInfo{\r\n    width:100%;\r\n    box-sizing: border-box;\r\n    padding:30px 30px 50px 30px;\r\n    background:#fff;\r\n    font-family: \"GongGothicLight\";\r\n    font-size:13px;\r\n    color:#333;\r\n    display:flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.videoInfo ul{\r\n    width:100%;\r\n    margin-top:30px;\r\n    box-sizing: border-box;\r\n    padding:10px;\r\n    border:1px solid #f5f5f5;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.videoInfo ul li{\r\n    width:100%;\r\n    border:1px solid #f5f5f5;\r\n    box-sizing: border-box;\r\n    padding-left:10px;\r\n    border-top:none;\r\n    background:#f9f9f9;\r\n    height:40px;\r\n    line-height: 40px;\r\n    display:flex;\r\n    justify-content: space-between;\r\n}\r\n.videoInfo ul li:first-child{\r\n    border-top:1px solid #f5f5f5;\r\n}\r\n.videoInfo ul li{\r\n    text-align: left;\r\n}\r\n.videoInfo ul li span{\r\n    width:70%;\r\n    height:100%;\r\n    display: block;\r\n    float: right;\r\n    padding-left:10px;\r\n    background:#fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
