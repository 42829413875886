import React from 'react';
import styles from './SpecialstageBoard.module.css';
import { isMobile } from 'react-device-detect';


function SpecialstageBoard(){
 return(<div className={`${styles.SpecialstageBoard} ${isMobile&&styles.MobilespecialstageBoard}`}>
    <h1 className={styles.waitingText}>Sorry, this page is awaiting an update.</h1>
 </div>);
}

export default SpecialstageBoard;