// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/behindstoryboard_bg.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/mobilebehindstoryboard_bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BehindStoryBoard_BehindStoryBoard__S6ax\\+{
    width:100%;
    height:100vh;
    background:no-repeat url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size:cover;
    background-position: center;
    display:flex;
    justify-content: center;
    align-items: center;
}
.BehindStoryBoard_MobilebehindstoryBoard__rUdbV{
    width:100%;
    height:100vh;
    background:no-repeat url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size:cover;
    background-position: center;
    display:flex;
    justify-content: center;
    align-items: center;
}
.BehindStoryBoard_waitingText__2bD2E{
    color:#fff;
    font-size:30px;
    font-family:"GongGothicBold";
    font-weight: 100;
    width:100%;
    box-sizing:border-box;
    padding:0 30px;
    word-break: break-word;
    text-align:center;
}
.BehindStoryBoard_MobilebehindstoryBoard__rUdbV .BehindStoryBoard_waitingText__2bD2E{
    font-size:18px;
}`, "",{"version":3,"sources":["webpack://./src/Board/BehindStoryBoard.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,4DAA6D;IAC7D,qBAAqB;IACrB,2BAA2B;IAC3B,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,YAAY;IACZ,4DAAmE;IACnE,qBAAqB;IACrB,2BAA2B;IAC3B,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,cAAc;IACd,4BAA4B;IAC5B,gBAAgB;IAChB,UAAU;IACV,qBAAqB;IACrB,cAAc;IACd,sBAAsB;IACtB,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB","sourcesContent":[".BehindStoryBoard{\r\n    width:100%;\r\n    height:100vh;\r\n    background:no-repeat url('../images/behindstoryboard_bg.jpg');\r\n    background-size:cover;\r\n    background-position: center;\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.MobilebehindstoryBoard{\r\n    width:100%;\r\n    height:100vh;\r\n    background:no-repeat url('../images/mobilebehindstoryboard_bg.jpg');\r\n    background-size:cover;\r\n    background-position: center;\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.waitingText{\r\n    color:#fff;\r\n    font-size:30px;\r\n    font-family:\"GongGothicBold\";\r\n    font-weight: 100;\r\n    width:100%;\r\n    box-sizing:border-box;\r\n    padding:0 30px;\r\n    word-break: break-word;\r\n    text-align:center;\r\n}\r\n.MobilebehindstoryBoard .waitingText{\r\n    font-size:18px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BehindStoryBoard": `BehindStoryBoard_BehindStoryBoard__S6ax+`,
	"MobilebehindstoryBoard": `BehindStoryBoard_MobilebehindstoryBoard__rUdbV`,
	"waitingText": `BehindStoryBoard_waitingText__2bD2E`
};
export default ___CSS_LOADER_EXPORT___;
