// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RozeMary_RozeMary__yDSFL{
    width:100%;
}
.RozeMary_RozeMary_Logo__4839v{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:30px 0 0 0;
    background-color:#69a745;
    border-top:1px solid #333;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.RozeMary_RozeMary_Logo__4839v a{
    display:inline-block;
    width:20%;
    margin-bottom:50px;
}
.RozeMary_RozeMary_Logo__4839v a img{
    width:100%;
    height:auto;
}
.RozeMary_RozeMary_Logo__4839v p{
    margin:0px;
    padding:20px 0;
    text-align: center;
    font-family:"GongGothicMedium";
    color:#333;
    font-size:24px;
    background:#f9f9f9;
    width:100%;
    border-bottom:1px solid #69a745;
}
.RozeMary_RozeMary_Logo__4839v p span{
    font-family:"GongGothicLight";
    font-size:18px;
}
.RozeMary_RMinnerRolling__\\+x--Y{
    width:100%;
    height:0px;
    padding-bottom:36%;
    position:relative;
}

/* 모바일 버전 */

.RozeMary_mobileRozeMary__e7bqA .RozeMary_RozeMary_Logo__4839v a{
    width:40%;
    margin-bottom:30px;
}
.RozeMary_mobileRozeMary__e7bqA .RozeMary_RozeMary_Logo__4839v p span{
    font-size:15px;
}
.RozeMary_mobileRozeMary__e7bqA .RozeMary_RMinnerRolling__\\+x--Y{
    padding-bottom:0;
    height:100vh;
}`, "",{"version":3,"sources":["webpack://./src/Components/RozeMary.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,wBAAwB;IACxB,yBAAyB;IACzB,sBAAsB;IACtB,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;AAC/B;AACA;IACI,oBAAoB;IACpB,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,8BAA8B;IAC9B,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,+BAA+B;AACnC;AACA;IACI,6BAA6B;IAC7B,cAAc;AAClB;AACA;IACI,UAAU;IACV,UAAU;IACV,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA,WAAW;;AAEX;IACI,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".RozeMary{\r\n    width:100%;\r\n}\r\n.RozeMary_Logo{\r\n    width:100%;\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding:30px 0 0 0;\r\n    background-color:#69a745;\r\n    border-top:1px solid #333;\r\n    flex-direction: column;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    background-position: center;\r\n}\r\n.RozeMary_Logo a{\r\n    display:inline-block;\r\n    width:20%;\r\n    margin-bottom:50px;\r\n}\r\n.RozeMary_Logo a img{\r\n    width:100%;\r\n    height:auto;\r\n}\r\n.RozeMary_Logo p{\r\n    margin:0px;\r\n    padding:20px 0;\r\n    text-align: center;\r\n    font-family:\"GongGothicMedium\";\r\n    color:#333;\r\n    font-size:24px;\r\n    background:#f9f9f9;\r\n    width:100%;\r\n    border-bottom:1px solid #69a745;\r\n}\r\n.RozeMary_Logo p span{\r\n    font-family:\"GongGothicLight\";\r\n    font-size:18px;\r\n}\r\n.RMinnerRolling{\r\n    width:100%;\r\n    height:0px;\r\n    padding-bottom:36%;\r\n    position:relative;\r\n}\r\n\r\n/* 모바일 버전 */\r\n\r\n.mobileRozeMary .RozeMary_Logo a{\r\n    width:40%;\r\n    margin-bottom:30px;\r\n}\r\n.mobileRozeMary .RozeMary_Logo p span{\r\n    font-size:15px;\r\n}\r\n.mobileRozeMary .RMinnerRolling{\r\n    padding-bottom:0;\r\n    height:100vh;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RozeMary": `RozeMary_RozeMary__yDSFL`,
	"RozeMary_Logo": `RozeMary_RozeMary_Logo__4839v`,
	"RMinnerRolling": `RozeMary_RMinnerRolling__+x--Y`,
	"mobileRozeMary": `RozeMary_mobileRozeMary__e7bqA`
};
export default ___CSS_LOADER_EXPORT___;
