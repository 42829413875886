// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conceptThumbs .swiper-slide{
    display:flex;
    justify-content: center;
    align-items: center;
    font-family: "GongGothicLight";
    font-size:13px;
    background: #f5f5f5;
    color:#333;
    box-sizing: border-box;
    border-right:1px solid #69a745;
}
.conceptThumbs .swiper-slide:last-child{
    border-right:none;
}
.conceptThumbs .swiper-slide-thumb-active{
    background:#69a745;
    color:#fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/Conceptart.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;IAC9B,cAAc;IACd,mBAAmB;IACnB,UAAU;IACV,sBAAsB;IACtB,8BAA8B;AAClC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".conceptThumbs .swiper-slide{\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-family: \"GongGothicLight\";\r\n    font-size:13px;\r\n    background: #f5f5f5;\r\n    color:#333;\r\n    box-sizing: border-box;\r\n    border-right:1px solid #69a745;\r\n}\r\n.conceptThumbs .swiper-slide:last-child{\r\n    border-right:none;\r\n}\r\n.conceptThumbs .swiper-slide-thumb-active{\r\n    background:#69a745;\r\n    color:#fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
