// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Maincontent_mainContents__0qNdp{
    width:100%;
    position:relative;
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/Components/Maincontent.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".mainContents{\r\n    width:100%;\r\n    position:relative;\r\n    z-index: 10;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContents": `Maincontent_mainContents__0qNdp`
};
export default ___CSS_LOADER_EXPORT___;
