import React from 'react';
import styles from './DrawingBoard.module.css';
import { isMobile } from 'react-device-detect';


function DrawingBoard(){
 return(<div className={`${styles.DrawingBoard} ${isMobile&&styles.MobiledrawingBoard}`}>
    <h1 className={styles.waitingText}>Sorry, this page is awaiting an update..</h1>
 </div>);
}

export default DrawingBoard;