// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutTitle_active__851UI{
    width:100%;
    height:0px;
    padding-bottom:15%;
    position:relative;
    background:#664498;
}
.AboutTitle_active__851UI ul{
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    overflow: hidden;
    box-sizing: border-box;
    padding-top:3.5%;
}
.AboutTitle_active__851UI ul li{
    width:100%;
    text-align: center;
    padding:0 40%;
    box-sizing: border-box;
    position:relative;
}

.AboutTitle_active__851UI ul li img{
    width:100%;
    height:auto;
}
.AboutTitle_active__851UI ul li a{
    width:20%;
    display: block;
    float: left;
}
.AboutTitle_active__851UI ul li span{
    width:78%;
    display: block;
    float: right;
}
.AboutTitle_btnShow__iVzd1{
    opacity:1;
    transition:0.5s ease-in-out;
}
.AboutTitle_btnHide__8jpHw{
    opacity:0;
    transition:0.5s ease-in-out;
}

/* 모바일 버전 */
.AboutTitle_mobileActive__90mSl{
    padding-bottom:25%;
}
.AboutTitle_mobileActive__90mSl ul li{
    padding:0 30%;
}`, "",{"version":3,"sources":["webpack://./src/Components/AboutTitle.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,UAAU;IACV,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,KAAK;IACL,MAAM;IACN,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,SAAS;IACT,cAAc;IACd,WAAW;AACf;AACA;IACI,SAAS;IACT,cAAc;IACd,YAAY;AAChB;AACA;IACI,SAAS;IACT,2BAA2B;AAC/B;AACA;IACI,SAAS;IACT,2BAA2B;AAC/B;;AAEA,WAAW;AACX;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB","sourcesContent":[".active{\r\n    width:100%;\r\n    height:0px;\r\n    padding-bottom:15%;\r\n    position:relative;\r\n    background:#664498;\r\n}\r\n.active ul{\r\n    position: absolute;\r\n    width:100%;\r\n    height:100%;\r\n    top:0;\r\n    left:0;\r\n    overflow: hidden;\r\n    box-sizing: border-box;\r\n    padding-top:3.5%;\r\n}\r\n.active ul li{\r\n    width:100%;\r\n    text-align: center;\r\n    padding:0 40%;\r\n    box-sizing: border-box;\r\n    position:relative;\r\n}\r\n\r\n.active ul li img{\r\n    width:100%;\r\n    height:auto;\r\n}\r\n.active ul li a{\r\n    width:20%;\r\n    display: block;\r\n    float: left;\r\n}\r\n.active ul li span{\r\n    width:78%;\r\n    display: block;\r\n    float: right;\r\n}\r\n.btnShow{\r\n    opacity:1;\r\n    transition:0.5s ease-in-out;\r\n}\r\n.btnHide{\r\n    opacity:0;\r\n    transition:0.5s ease-in-out;\r\n}\r\n\r\n/* 모바일 버전 */\r\n.mobileActive{\r\n    padding-bottom:25%;\r\n}\r\n.mobileActive ul li{\r\n    padding:0 30%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `AboutTitle_active__851UI`,
	"btnShow": `AboutTitle_btnShow__iVzd1`,
	"btnHide": `AboutTitle_btnHide__8jpHw`,
	"mobileActive": `AboutTitle_mobileActive__90mSl`
};
export default ___CSS_LOADER_EXPORT___;
