// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.halfBox{
    width:100%;
    height:18vw;
    position:relative;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.halfBox li{
    width:49%;
    height:100%;
}
.halfBox li img{
    width:100%;
    height:100%;
    object-fit: cover;
}

/* 모바일 버전 */
.landscapeContentBox .halfBox{
    flex-wrap:wrap;
    height:115vw;
}
.landscapeContentBox .halfBox li{
    width:100%;
    height:50%;
    box-sizing:border-box;
    padding:3px 0px;
}
.productshotContentBox .halfBox{
    flex-wrap:wrap;
    height:115vw;
}
.productshotContentBox .halfBox li{
    width:100%;
    height:50%;
    box-sizing:border-box;
    padding:3px 0px;
}`, "",{"version":3,"sources":["webpack://./src/Board/boardViewBox.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,SAAS;IACT,WAAW;AACf;AACA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;AACrB;;AAEA,WAAW;AACX;IACI,cAAc;IACd,YAAY;AAChB;AACA;IACI,UAAU;IACV,UAAU;IACV,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,cAAc;IACd,YAAY;AAChB;AACA;IACI,UAAU;IACV,UAAU;IACV,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".halfBox{\r\n    width:100%;\r\n    height:18vw;\r\n    position:relative;\r\n    display:flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n.halfBox li{\r\n    width:49%;\r\n    height:100%;\r\n}\r\n.halfBox li img{\r\n    width:100%;\r\n    height:100%;\r\n    object-fit: cover;\r\n}\r\n\r\n/* 모바일 버전 */\r\n.landscapeContentBox .halfBox{\r\n    flex-wrap:wrap;\r\n    height:115vw;\r\n}\r\n.landscapeContentBox .halfBox li{\r\n    width:100%;\r\n    height:50%;\r\n    box-sizing:border-box;\r\n    padding:3px 0px;\r\n}\r\n.productshotContentBox .halfBox{\r\n    flex-wrap:wrap;\r\n    height:115vw;\r\n}\r\n.productshotContentBox .halfBox li{\r\n    width:100%;\r\n    height:50%;\r\n    box-sizing:border-box;\r\n    padding:3px 0px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
