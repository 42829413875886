import React from 'react';
import styles from './BehindStoryBoard.module.css';
import { isMobile } from 'react-device-detect';


function BehindStoryBoard(){
 return(<div className={`${styles.BehindStoryBoard} ${isMobile&&styles.MobilebehindstoryBoard}`}>
    <h1 className={styles.waitingText}>Sorry, this page is awaiting an update.</h1>
 </div>);
}

export default BehindStoryBoard;