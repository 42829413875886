import React from 'react';
import styles from './CommunityBoard.module.css';
import { isMobile } from 'react-device-detect';


function CommunityBoard(){
 return(<div className={`${styles.CommunityBoard} ${isMobile&&styles.MobilecommunityBoard}`}>
    <h1 className={styles.waitingText}>Sorry, this page is awaiting open.</h1>
 </div>);
}

export default CommunityBoard;