// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_images{
    padding:0 70px;
    box-sizing: border-box;
}

/* 모바일 버전 */
.webdesignContentBox .content_images{
    padding:0 10px;
}`, "",{"version":3,"sources":["webpack://./src/Board/VisualdesignBoard.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA,WAAW;AACX;IACI,cAAc;AAClB","sourcesContent":[".content_images{\r\n    padding:0 70px;\r\n    box-sizing: border-box;\r\n}\r\n\r\n/* 모바일 버전 */\r\n.webdesignContentBox .content_images{\r\n    padding:0 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
